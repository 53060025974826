<template>
  <div class="travelersBooking__list-item-bottom js-travelers-form">
    <form class="form form--simple js-ajaxForm" @submit.prevent="submitHandler">
      <label class="form__label form__label--text">
        <span class="form__field-wrapper">
          <input
            class="form__field"
            :class="{invalid: $v.email.$dirty && !$v.email.required}"
            type="email"
            name="email"
            placeholder="Enter e-mail"
            v-model="email"
          />
        </span>
      </label>
      <div class="form__label form__label--button">
        <button class="form__button">
          <span class="text">Change</span>
          <span class="icon"><SvgIcon name="checked"/></span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import { required } from 'vuelidate/lib/validators'

  export default {
    name: 'TravelersForm',
    data: () => ({
      email: '',
    }),
    validations: {
      email: { required },
    },
    components: {
      SvgIcon,
    },
    methods: {
      submitHandler () {
        if (this.$v.$invalid) {
          this.$v.$touch()
        }
        console.log(this.email)
      },
    },
  }
</script>
