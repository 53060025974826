<template>
  <div>
    <div v-if="!travelers.length" class="travelersBooking__empty"></div>

    <div v-if="type === 'confirmation'" class="travelersBooking__header js-travelers-header">
      <div
        class="travelersBooking__header-item"
        v-for="item of header"
        :key="item.title">{{item.title}}
      </div>
    </div>

    <div class="travelersBooking__list">
      <div
        class="travelersBooking__list-item js-accordion-item"
        v-for="(traveler, index) of travelers"
        :key="index"
      >
        <div class="travelersBooking__list-item-top">
          <div class="travelersBooking__list-item-cell">
            <div
              v-if="type === 'confirmation'"
              class="travelersBooking__list-item-avatar"
              :style="{backgroundImage: `url(${traveler.image})`}">
            </div>
            <div class="travelersBooking__list-item-name">{{traveler.firstName + ' ' + traveler.lastName}}</div>
          </div>
<!--          <div v-if="type === 'travelers'" class="travelersBooking__list-item-cell">-->
<!--            <div class="travelersBooking__list-item-info">{{traveler.info}}</div>-->
<!--          </div>-->
          <div v-if="type === 'confirmation'" class="travelersBooking__list-item-cell">
            <div class="travelersBooking__list-item-text-mobile">Ticket</div>
            <div class="travelersBooking__list-item-text">{{traveler.ticket}}</div>
          </div>
          <div v-if="type === 'confirmation'" class="travelersBooking__list-item-cell">
            <div class="travelersBooking__list-item-text-mobile">Seat</div>
            <div class="travelersBooking__list-item-text">{{traveler.seat}}</div>
          </div>

          <div class="travelersBooking__list-item-cell" style="justify-content: space-between; padding-right: 32px">
            <div class="travelersBooking__list-item-text">{{traveler.email}}</div>
            <div class="travelersBooking__list-item-button-wr">
              <div v-if="type === '!travelers'" class="travelersBooking__list-item-edit js-editTravelers"
                   @click="toggleHtml">
                <SvgIcon name="pencil"/>
              </div>
              <div
                class="travelersBooking__list-item-remove js-removeTravelers"
                @click="showModal(traveler.id)"
              >
                <SvgIcon name="close"/>
              </div>
            </div>
          </div>
        </div>
        <TravelersForm v-if="type === 'confirmation'"/>
      </div>
    </div>

    <QuestionPopup
      :name="'questionBookingTravelers'"
      :title="'The traveler will be removed'"
      :text="'Are you sure?'"
      @remove="remove"
    />
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import TravelersForm from '@/views/booking/Travelers/TravelersForm'
  import QuestionPopup from '@/components/common/modals/QuestionPopup'

  export default {
    props: ['type', 'travelers'],
    name: 'TravelersList',
    data: () => ({
      header: [{
        title: 'Traveler',
      }, {
        title: 'Ticket',
      }, {
        title: 'Seat',
      }, {
        title: 'Email',
      }],
    }),
    methods: {
      toggleHtml (event) {
        const item = event.currentTarget
          .closest('.js-accordion-item')
          .querySelector('.js-travelers-form')

        this.$root.animations.slideToggle(item)
      },
      showModal (item) {
        this.$emit('showModal')
        this.$store.commit('currentTraveler', item)
      },
      remove () {
        this.$store.commit('removeTraveler')
      },
    },
    components: {
      SvgIcon,
      TravelersForm,
      QuestionPopup,
    },
  }
</script>
