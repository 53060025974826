<template>
  <div class="bookingBlock__content">
    <vue-scroll :ops="ops">
      <div class="bookingBlock__content-inner">
        <div class="travelersBooking">
          <div class="travelersBooking__content">
            <div class="travelersBooking__content-left">
              <div class="travelersBooking__title">Travelers</div>
              <TravelersList
                :type="'travelers'"
                :title="'The traveler will be removed'"
                :text="'Are you sure?'"
                :travelers="travelers"
              />
            </div>
            <div class="travelersBooking__content-right">
              <div class="travelersBooking__title">Add travelers</div>
              <div class="travelersBooking__info">
                <article>
                  <p>Users are able to add other users who are already members of FLYJETS</p>
                  <p>Non-members will receive email invitations to join FLYJETS, add their info to the trip (in
                    Travelers
                    tab) and <b>possibly split payment with group</b></p>
                </article>
              </div>
              <div class="profileDashboard__add-members">
<!--                <div class="profileDashboard__add-members-tabs js-tabBox">-->
<!--                  <div class="profileDashboard__add-members-tabs-nav">-->
<!--                    <div class="profileDashboard__add-members-tabs-nav-item js-tabNavItem"-->
<!--                         data-id="FlyJets-member" @click="tabActivate">FlyJets member-->
<!--                    </div>-->
<!--                    <div class="profileDashboard__add-members-tabs-nav-item js-tabNavItem"-->
<!--                         data-id="Invite-by-email" @click="tabActivate">Invite by email-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="profileDashboard__add-members-tabs-content">-->
<!--                    <div class="profileDashboard__add-members-tabs-content-item js-tabContentItem"-->
<!--                         data-id="FlyJets-member">-->
<!--                      <form-->
<!--                        class="form form&#45;&#45;addMembers js-ajaxForm error"-->
<!--                        data-href="/json/test.json"-->
<!--                        data-action="addMember"-->
<!--                        id="addMemberForm"-->
<!--                      >-->
<!--                        <label class="form__label form__label&#45;&#45;text">-->
<!--                          <div class="form__field-wrapper">-->
<!--                            <input class="form__field" type="email" name="email" placeholder="Type there..."-->
<!--                                   required="required"/>-->
<!--                            <span class="form__field-error"><SvgIcon name="warning"/>not found!</span>-->
<!--                            <span class="form__field-success"><SvgIcon name="checked-green"/>found!</span>-->
<!--                          </div>-->
<!--                        </label>-->
<!--                        <div class="form__label form__label&#45;&#45;button">-->
<!--                          &lt;!&ndash;                          <Spinner/>&ndash;&gt;-->
<!--                          <button class="form__button"><span class="text">Add</span></button>-->
<!--                        </div>-->
<!--                      </form>-->
<!--                    </div>-->

<!--                    <div class="profileDashboard__add-members-tabs-content-item js-tabContentItem"-->
<!--                         data-id="Invite-by-email">-->
<!--                      <form class="form form&#45;&#45;addMembers js-ajaxForm" data-href="/json/test.json"-->
<!--                            data-action="inviteMember"-->
<!--                            id="inviteMemberForm">-->
<!--                        <label class="form__label form__label&#45;&#45;text">-->
<!--                          <div class="form__field-wrapper">-->
<!--                            <input class="form__field" type="email" name="email" placeholder="Type there..."-->
<!--                                   required="required"/>-->
<!--                          </div>-->
<!--                        </label>-->
<!--                        <div class="form__label form__label&#45;&#45;button">-->
<!--                          &lt;!&ndash;                          <Spinner/>&ndash;&gt;-->
<!--                          <button class="form__button"><span class="text">Add</span></button>-->
<!--                        </div>-->
<!--                      </form>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

                <div ref="addTraveler" class="profileDashboard__add-members-input js-acc-item">
                  <form
                    class="form form--addTravelers js-addTravelersForm js-ajaxForm"
                    @submit.prevent="submitHandler"
                  >
                    <div class="form__list" style="margin-bottom: 5px">
                      <div class="travelersBooking__form-top form__field-wrapper">
                        <input
                          class="form__field"
                          type="email"
                          v-model="memberEmail"
                          id="memberEmail"
                          placeholder="Enter e-mail"
                          autocomplete="off"
                        />
                        <div class="form__icon">
                          <SvgIcon name="checked"/>
                        </div>
                        <span v-if="$v.memberEmail.$dirty && !$v.memberEmail.required"
                              class="form__field-invalid form__field-invalid--right-side">
                          This field is required
                        </span>
                      </div>
                      <div class="profileDashboard__add-members-input-header js-acc-title" @click="toggleHtml">
                        <div class="profileDashboard__add-members-input-header-text open">Or&nbsp;<b>input name and info</b>
                        </div>
                        <div class="profileDashboard__add-members-input-header-icon">
                          <SvgIcon name="down-arrow"/>
                        </div>
                      </div>
                      <div class="profileDashboard__add-members-input-content">
                        <div class="profileDashboard__add-members-input-form">
                          <div class="form form--inputMembers js-ajaxForm" id="inputMemberForm">
                            <Spinner/>
                            <label class="form__label form__label--text">
                              <span class="form__field-wrapper">
                                <span class="form__caption">First name:</span>
                                <input
                                  class="form__field"
                                  type="text"
                                  v-model="firstName"
                                />
                              </span>
                            </label>
                            <label class="form__label form__label--text">
                              <span class="form__field-wrapper">
                                <span class="form__caption">Last name:</span>
                                <input
                                  class="form__field"
                                  type="text"
                                  v-model="lastName"
                                />
                              </span>
                            </label>
  <!--                          <label class="form__label form__label&#45;&#45;date">-->
  <!--                            <div class="form__field-wrapper">-->
  <!--                              <div class="form__caption">Date of birth:</div>-->
  <!--                              <a-date-picker-->
  <!--                                placeholder="click to change"-->
  <!--                                :showToday="false"-->
  <!--                                :allowClear="false"-->
  <!--                                v-model="date"-->
  <!--                                format="MM/DD/YYYY"-->
  <!--                                :getCalendarContainer="getPickerContainer"-->
  <!--                              />-->
  <!--                            </div>-->
  <!--                          </label>-->
                            <label class="form__label form__label--address" style="margin-bottom: 0;" >
                              <span class="form__field-wrapper">
                                <span class="form__caption">Email:</span>
                                <input
                                  class="form__field"
                                  type="text"
                                  v-model="email"
                                />
                              </span>
                            </label>
<!--                            <div class="form__label form__label&#45;&#45;button">-->
<!--                              <button class="form__button" type="submit">-->
<!--                                <span class="icon"><SvgIcon name="cloud"/></span>-->
<!--                                <span class="text">Upload passenger identification</span>-->
<!--                              </button>-->
<!--                            </div>-->
                          </div>
                        </div>
<!--                        <div class="profileDashboard__add-members-input-decor">-->
<!--                          <SvgIcon name="plus"/>-->
<!--                        </div>-->
                      </div>
                    </div>
                    <div class="form__button-wr">
                      <div class="form__label form__label--button">
                        <button class="form__button">
                          <span class="text">Add</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <router-link class="bookingBlock__content-prev js-bookingNav-item" to="aircraft">
          <span class="icon"><SvgIcon name="left-arrow"/></span>
          <span class="text">Back</span>
        </router-link>

        <router-link class="bookingBlock__content-next js-bookingNav-item" to="terms">
          <span class="icon"><SvgIcon name="right-arrow"/></span>
          <span class="text">Next</span>
        </router-link>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
  import TravelersList from '@/views/booking/Travelers/TravelersList'
  import Spinner from '@/components/common/Spinner/Spinner'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import { Tabs } from '@/js/plugins/tabs'
  import { required } from 'vuelidate/lib/validators'

  export default {
    name: 'travelers',
    data: () => ({
      memberEmail: '',
      firstName: '',
      lastName: '',
      address: '',
      email: '',
      // date: '',
      tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    validations: {
      memberEmail: { required },
      // address: { required },
      // firstName: { required },
      // lastName: { required },
      // email: { required }
      // date: { required }
    },
    computed: {
      travelers () {
        return this.$store.state.bookingFromSearch.travelers
      },
    },
    mounted () {
      this.tabs.addActiveForMouted()
    },
    methods: {
      tabActivate (event) {
        this.tabs.addActiveForClick(event.target)
      },
      toggleHtml (event) {
        const item = event.currentTarget.closest('.js-acc-item').querySelector('.profileDashboard__add-members-input-content')
        const status = this.$root.animations.slideToggle(item, 140);
        status ? this.$refs.addTraveler.classList.add('open') : this.$refs.addTraveler.classList.remove('open')
      },
      async submitHandler () {
        if (this.$v.$invalid && this.email.length === 0) {
          this.$v.$touch()
          return false
        }
        const data = {
          memberEmail: this.memberEmail,
          newMember: false,
        }
        if (this.email) {
          data.memberEmail = this.email
          data.newMember = true
          data.firstName = this.firstName
          data.lastName = this.lastName
          data.id = Date.now()
        }

        const result = await this.$store.dispatch('getMember', data);

        if (!result) {
          const message = {
            title: 'No Member Found',
            text: 'No member found with this email.',
          }

          this.$modal.show('ThanksPopup', {message})
        } else {
          const message = {
            title: 'Traveler Exists',
            text: 'The traveler you are trying to add is already added.',
          }

          if (result === 'Traveler Exists') {
            this.$modal.show('ThanksPopup', {message})
          }
        }
        this.memberEmail = '';
        this.firstName = ''
        this.lastName = ''
        this.email = ''
      },
      // getPickerContainer (trigger) {
      //   return document.querySelector('.whitePicker')
      // }
    },
    components: {
      TravelersList,
      SvgIcon,
      Spinner,
    },
  }
</script>
